import axios from 'axios';
import constants from '../constants/constants.js';
import qs from 'qs';
import { isEmpty, find, map, sortBy, reverse } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import router from '../../../route';
import DOMPurify from 'isomorphic-dompurify';

export default {
    async get_data_from_server2(_, obj) {
        const response = await axios.post(
            constants.url + 'EXT/acces_bdd.php',
            qs.stringify({
                action: obj.request,
                pk_part: obj.pk_part,
                cx_id: obj.cx_id,
                user: obj.user,
                time: obj.time,
                status: obj.status,
                user_info: obj.user_info,
                option: obj.options,
                contrat: obj.contrats,
                param1002: obj.param1002,
                sujet: obj.sujet,
                message: obj.message,
                checkbox: obj.checkbox,
                attachments: obj.attachments,
                tab_options: obj.tab_options,
                current_part_only: obj.current_part_only,
                mode: obj.mode,
                search: obj.search,
                field: obj.field,
                user_id: obj.user_id,
                year: obj.year,
                list_part: obj.list_part,
                demande_id: obj.demande_id,
                context: obj.context,
            }),
        );
        return response.data;
    },
    async connect_user({ dispatch, commit, rootGetters, state, rootState }, obj) {
        axios.defaults.withCredentials = true;
        const response = await axios.post(
            constants.url + 'CX/scr-login2.php',
            qs.stringify({
                action: 'check_connexion',
                TXT_LANGUE: 'FR',
                TXT_LOGIN: obj.login,
                TXT_PASSWORD: obj.password,
            }),
        );
        if (response.data.success) {
            commit('SET_PK_USER', response.data.userID);
            commit('SET_USER_TYPE', response.data.role);
            commit('SET_CAN_USURPATE', response.data.usurpation);

            if (!rootGetters['isSimpleUser']) {
                const res = await dispatch('get_data_from_server2', {
                    request: 'get_part_profil',
                    pk_part: state.PKUser,
                    current_part_only: false,
                });
                commit('broadcaster/SET_USER_PROFIL', res.profil);
                commit('broadcaster/SET_RIGHTS_LEVEL', await dispatch('convertRight', res.droit));
                if (rootGetters['isSubscriberUser'] || rootGetters['isBroadcasterUser']) {
                    dispatch('broadcaster/getLinkPart', rootState.broadcaster.userProfil.cx_id);
                }
                await dispatch('broadcaster/fetchPart');
            } else {
                const tmp = await dispatch('get_data_from_server2', {
                    request: 'get_indiv_profil',
                });
                commit('broadcaster/SET_USER_PROFIL', tmp.profil);
            }
            router.push('/Accueil');
            return response.data;
        } else {
            return response.data;
        }
    },

    async controlUser({ dispatch, commit, rootGetters, state, rootState }, PK) {
        commit('SET_LOADING_SCREEN', true);
        const response = await axios.post(
            constants.url + 'EXT/acces_bdd.php',
            qs.stringify({
                action: 'control_user',
                user_id: PK,
            }),
        );
        if (response.data.success) {
            commit('SET_PK_USER', response.data.userID);
            commit('SET_USER_TYPE', response.data.role);
            commit('OPEN_CLOSE_HELP', false);
            commit('OPEN_CLOSE_POP_UP_ACCOUNT', false);
            commit('broadcaster/CLEAR_WALLET');

            if (!rootGetters['isSimpleUser']) {
                const res = await dispatch('get_data_from_server2', {
                    request: 'get_part_profil',
                    pk_part: state.PKUser,
                    current_part_only: false,
                });
                commit('broadcaster/SET_USER_PROFIL', res.profil);
                commit('broadcaster/SET_RIGHTS_LEVEL', await dispatch('convertRight', res.droit));
                if (rootGetters['isSubscriberUser'] || rootGetters['isBroadcasterUser']) {
                    dispatch('broadcaster/getLinkPart', rootState.broadcaster.userProfil.cx_id);
                }
                await dispatch('broadcaster/fetchPart');
                commit('SET_LOADING_SCREEN', false);
            } else {
                const tmp = await dispatch('get_data_from_server2', {
                    request: 'get_indiv_profil',
                });
                commit('broadcaster/SET_USER_PROFIL', tmp.profil);
                commit('SET_LOADING_SCREEN', false);
            }
            commit('SET_CONTROL_MODE', true);
            router.push('/Accueil');
            commit('broadcaster/SET_LEFT_SIDER_SELECTED_BUTTON', [0]);
            commit('SET_USURP_KEY');
        } else {
            commit('SET_LOADING_SCREEN', false);
        }
    },

    async stopControlUser({ dispatch, commit, rootGetters, state, rootState }) {
        commit('SET_LOADING_SCREEN', true);
        const response = await axios.post(
            constants.url + 'EXT/acces_bdd.php',
            qs.stringify({
                action: 'stop_control_user',
            }),
        );
        if (response.data.success) {
            commit('SET_PK_USER', response.data.userID);
            commit('SET_USER_TYPE', response.data.role);
            commit('OPEN_CLOSE_HELP', false);
            commit('OPEN_CLOSE_POP_UP_ACCOUNT', false);
            commit('broadcaster/CLEAR_WALLET');

            if (!rootGetters['isSimpleUser']) {
                const res = await dispatch('get_data_from_server2', {
                    request: 'get_part_profil',
                    pk_part: state.PKUser,
                    current_part_only: false,
                });
                commit('broadcaster/SET_USER_PROFIL', res.profil);
                commit('broadcaster/SET_RIGHTS_LEVEL', await dispatch('convertRight', res.droit));
                if (rootGetters['isSubscriberUser'] || rootGetters['isBroadcasterUser']) {
                    dispatch('broadcaster/getLinkPart', rootState.broadcaster.userProfil.cx_id);
                }
                await dispatch('broadcaster/fetchPart');
                commit('SET_LOADING_SCREEN', false);
            } else {
                const tmp = await dispatch('get_data_from_server2', {
                    request: 'get_indiv_profil',
                });
                commit('broadcaster/SET_USER_PROFIL', tmp.profil);
                commit('SET_LOADING_SCREEN', false);
            }
            commit('SET_CONTROL_MODE', false);
            router.push('/Accueil');
            commit('broadcaster/SET_LEFT_SIDER_SELECTED_BUTTON', [0]);
            commit('SET_USURP_KEY');
        } else {
            commit('SET_LOADING_SCREEN', true);
        }
    },

    async convertRight(_, right) {
        const tab = {
            901: 'Accès tarificateur (01)',
            902: 'Accès Adhérent (02)',
            9004: 'Souscripteur coll (bail court / 10)',
            9010: 'Souscripteur coll (10)',
            9015: 'Souscripteur coll (Admin / 15)',
            9020: 'Diffuseur (Utilisateur / 20)',
            9021: 'Diffuseur (Utilisateur MGP / 21)',
            9025: 'Diffuseur (Admin / 25)',
            9026: 'Diffuseur (Utilisateur courtage / 26)',
            9030: 'Super admin (30)',
        };
        return parseInt(Object.keys(tab).find((key) => tab[key] === right));
    },

    async get_data_from_server3(_, obj) {
        const response = await axios.get(constants.url + 'CX/src-account_perdu.php', {
            params: {
                action: obj.request,
                TXT_PWD1: obj.TXT_PWD1,
                TXT_PWD2: obj.TXT_PWD2,
            },
        });
        return response.data;
    },
    async create_new_account(_, obj) {
        const response = await axios.post(
            constants.url + 'mire/new_account.php',
            qs.stringify({
                ACTION: obj.request,
                TXT_NOM: obj.name,
                TXT_PRENOM: obj.firstName,
                TXT_MAIL: obj.mail,
                TXT_MAIL_2: obj.confirmMail,
                TXT_TEL: obj.phone,
                TXT_CODE: obj.extranetCode,
            }),
        );
        return response.data;
    },
    async forgot_password(_, obj) {
        const response = await axios.post(
            constants.url + 'CX/src-account_perdu.php',
            qs.stringify({
                action: obj.request,
                lg: 'FR',
                TXT_MAIL: obj.mail,
            }),
        );
        return response.data;
    },
    async dailyCompensation(_, obj) {
        const response = await axios.post(
            constants.url + 'EXT/functions/inc-form_ij.php',
            qs.stringify({
                action: obj.request,
                user: obj.PK,
                IJ_COLL_SUIVI_PAR: obj.followBy,
                IJ_COLL_RAISON_SOCIALE: obj.socialReason,
                IJ_COLL_ADRESSE_PART: obj.partAdresse,
                IJ_COLL_TEL_PART: obj.partTel,
                IJ_COLL_MAIL_PART: obj.partMail,
                IJ_COLL_NUM_CONTRAT_PART: obj.partNumContrat,
                IJ_COLL_DATE_EFFET_CONTRAT_PART: obj.partDateEffetContrat,
                NOM_PRENOM: obj.name,
                ADRESSE_ADH: obj.adhAdresse,
                DATE_NAISSANCE: obj.birthDate,
                NUM_SEC_SOC: obj.numSec,
                IJ_COLL_DATE_ENTREE: obj.dateEnter,
                IJ_COLL_DATE_ADHESION_CONTRAT: obj.dateAdhesion,
                IJ_COLL_DATE_PREMIER_JOUR_ARRET: obj.firstDay,
                IJ_COLL_ARRET_CONSECUTIF: obj.stop,
                IJ_COLL_POURCENT_1: obj.pourcent1,
                IJ_COLL_DATE_DEBUT_1: obj.beginDate1,
                IJ_COLL_DATE_FIN_1: obj.endDate1,
                IJ_COLL_POURCENT_2: obj.pourcent2,
                IJ_COLL_DATE_DEBUT_2: obj.beginDate2,
                IJ_COLL_DATE_FIN_2: obj.endDate2,
                IJ_COLL_POURCENT_3: obj.pourcent3,
                IJ_COLL_DATE_DEBUT_3: obj.beginDate3,
                IJ_COLL_DATE_FIN_3: obj.endDate3,
                IJ_COLL_POURCENT_4: obj.pourcent4,
                IJ_COLL_DATE_DEBUT_4: obj.beginDate4,
                IJ_COLL_DATE_FIN_4: obj.endDate4,
                IJ_COLL_DATE_DEBUT_INTER_MGP: obj.beginDateInterMgp,
                IJ_COLL_DATE_FIN_INTER_MGP: obj.endDateInterMgp,
                IJ_COLL_MODE_PAIEMENT: obj.paymentMode,
                IJ_COLL_LIEU_SIGNATURE: obj.signPlace,
                FILE: obj.file,
            }),
        );
        return response.data;
    },
    async getDocs() {
        const response = await axios.get(constants.url + 'EXT/functions/zone_echange.php', {
            params: {
                action: 'get_document',
            },
        });
        return response.data;
    },
    async getContratsIndiv({ commit }) {
        const response = await axios.get(constants.url + 'EXT/indiv/functions_indiv.php', {
            params: {
                action: 'get_contrats_indiv',
            },
        });
        if (response.data.success) {
            commit('broadcaster/SET_CONTRACTS_INDIV', response.data.contrats);
        }
    },
    async getDocsIndiv({ commit }) {
        const response = await axios.get(constants.url + 'EXT/indiv/functions_indiv.php', {
            params: {
                action: 'get_documents_contrats',
            },
        });

        commit(
            'broadcaster/SET_FILTERED_DOCUMENTS',
            reverse(
                sortBy(response.data.path, [
                    function (o) {
                        return o.creeLe;
                    },
                ]),
                { root: true },
            ),
        );
        commit('broadcaster/SET_DOCUMENT_REFERENCES', response.data.references, {
            root: true,
        });
    },
    async getCotisationsIndiv({ rootState, commit }) {
        const response = await axios.get(constants.url + 'EXT/indiv/functions_indiv.php', {
            params: {
                action: 'get_cotisations_indiv',
            },
        });
        commit('broadcaster/SET_MY_COTISATIONS', response.data.cotisations, { root: true });
    },
    async getIndemnisationIndiv({ commit }) {
        const response = await axios.get(constants.url + 'EXT/indiv/functions_indiv.php', {
            params: {
                action: 'get_prestations_indiv',
            },
        });
        commit('broadcaster/SET_MY_INDEMNISATION', response.data.prestations, {
            root: true,
        });
    },

    async setUploadFiles(_, files) {
        const promises = [];
        for (let element of files) {
            const formData = new FormData();
            formData.append('action', 'send_document');
            formData.append('file', element);
            const request = new XMLHttpRequest();
            request.withCredentials = true;
            request.open('POST', constants.url + 'EXT/functions/zone_echange.php');
            request.send(formData);
            promises.push(
                new Promise((resolve, reject) => {
                    request.onload = () => resolve(request.responseText);
                    request.onerror = () => reject(new Error("Erreur lors de l'envoi du fichier : " + request.statusText));
                }),
            );
        }

        return await Promise.all(promises);
    },
    async get_fav({ dispatch, commit }) {
        const res = await dispatch('get_data_from_server2', {
            request: 'get_favorites',
        });
        commit('broadcaster/SET_FAVORITE', res.preferred_subcribers, {
            root: true,
        });
    },
    async set_fav({ rootState, dispatch }, comp_PK) {
        await dispatch('get_data_from_server2', {
            request: 'add_favorite',
            pk_part: comp_PK,
            context: rootState.broadcaster.broadcasterSelected.PK,
        });
    },
    async del_fav({ rootState, dispatch }, el) {
        if (!el.context) {
            el = {
                ...el,
                context: { PK: rootState.broadcaster.broadcasterSelected.PK },
                original_PK: el.PK,
            };
        }
        await dispatch('get_data_from_server2', {
            request: 'delete_favorite',
            pk_part: el.original_PK,
            context: el.context.PK,
        });
    },
    async get_one_subscriber({ dispatch, commit }, pk) {
        const res = await dispatch('get_data_from_server2', {
            request: 'get_one_subscriber',
            pk_part: pk,
        });
        if (res.info) {
            commit('broadcaster/SET_FOCUSED_COMPANY', res.info, { root: true });
        } else {
            commit('broadcaster/SET_FOCUSED_COMPANY', [], { root: true });
        }
    },
    async get_arrets({ rootState, dispatch, commit }, { PK, context = false }) {
        const response = await dispatch('get_data_from_server2', {
            request: 'get_arrets',
            pk_part: PK,
            context: context ? context : rootState.broadcaster.broadcasterSelected.PK,
        });
        if (response.success) {
            commit(
                'broadcaster/ADD_COMPANY_INFO',
                {
                    company: rootState.broadcaster.focusedCompany,
                    key: 'stoppedPeople',
                    data: response.arrets,
                },
                { root: true },
            );
        }
    },
    async get_contact({ rootState, dispatch, commit }, { PK, context = false }) {
        const response = await dispatch('get_data_from_server2', {
            request: 'get_liste_contact_from_etab',
            pk_part: PK,
            context: context ? context : rootState.broadcaster.broadcasterSelected.PK,
        });
        if (response.success) {
            commit(
                'broadcaster/ADD_COMPANY_INFO',
                {
                    company: rootState.broadcaster.focusedCompany,
                    key: 'contacts',
                    data: response.utilisateurs,
                },
                { root: true },
            );
        }
    },
    async get_members({ rootState, dispatch, commit }, { PK, allMemebers = false, context = false }) {
        commit('broadcaster/SET_PENDING_ADH', true);

        let default_context = undefined;
        if (!context && !isEmpty(rootState.broadcaster.broadcasterSelected)) {
            default_context = rootState.broadcaster.focusedCompany.context.PK;
        }
        const response = await dispatch('get_data_from_server2', {
            request: 'get_members_by_options',
            tab_options: allMemebers ? [] : rootState.broadcaster.filteredContracts,
            pk_part: allMemebers ? PK : '',
            context: context ? context : default_context,
        });
        if (response.success) {
            commit(
                'broadcaster/ADD_COMPANY_INFO',
                {
                    company: rootState.broadcaster.focusedCompany,
                    key: 'members',
                    data: response.members ? response.members : [],
                },
                { root: true },
            );
        }
        commit('broadcaster/SET_PENDING_ADH', false);
    },
    async get_cotisation({ rootState, dispatch, commit }, { PK, allCot = false, context = false }) {
        commit('broadcaster/SET_PENDING_COT', true);
        const response = await dispatch('get_data_from_server2', {
            request: 'recherche_cotisations_partenaire',
            tab_options: allCot ? [] : rootState.broadcaster.filteredContracts,
            pk_part: PK,
            context: context ? context : rootState.broadcaster.broadcasterSelected.PK,
        });
        if (response.success) {
            // each(response.cotisations, (option) => {
            //     for (const year of option.cotisation) {
            //         if(Array.isArray(year)){

            //         }
            //     }
            // })
            commit(
                'broadcaster/ADD_COMPANY_INFO',
                {
                    company: rootState.broadcaster.focusedCompany,
                    key: 'cotisations',
                    data: response.cotisations ? response.cotisations : [],
                },
                { root: true },
            );
        }
        commit('broadcaster/SET_PENDING_COT', false);
    },
    async get_demands({ rootState, dispatch, commit }, { PK, context = false }) {
        const response = await dispatch('get_data_from_server2', {
            request: 'get_liste_demande',
            pk_part: PK,
            context: context ? context : rootState.broadcaster.broadcasterSelected.PK,
        });
        if (response.success) {
            commit(
                'broadcaster/ADD_COMPANY_INFO',
                {
                    company: rootState.broadcaster.focusedCompany,
                    key: 'demands',
                    data: map(response.liste_demande, (d) => {
                        return { ...d, id: uuidv4() };
                    }),
                },
                { root: true },
            );
        }
    },
    async get_rib_iban({ rootState, dispatch, commit }, { PK, context = false }) {
        const response = await dispatch('get_data_from_server2', {
            request: 'recherche_rib_collectif',
            pk_part: PK,
            context: context ? context : rootState.broadcaster.broadcasterSelected.PK,
        });
        if (response.success) {
            commit(
                'broadcaster/ADD_COMPANY_INFO',
                {
                    company: rootState.broadcaster.focusedCompany,
                    key: 'rib_iban',
                    data: map(response.rib, (d) => {
                        return { ...d, id: uuidv4() };
                    }),
                },
                { root: true },
            );
        }
    },
    async get_contracts({ rootState, dispatch, commit }, { PK, context = false }) {
        const response = await dispatch('get_data_from_server2', {
            request: 'recherche_contrat_partenaire',
            pk_part: PK,
            context: context ? context : rootState.broadcaster.broadcasterSelected.PK,
        });
        if (response.success) {
            commit(
                'broadcaster/ADD_COMPANY_INFO',
                {
                    company: rootState.broadcaster.focusedCompany,
                    key: 'contracts',
                    data: response.contrats,
                },
                { root: true },
            );
        }
    },
    async get_documents({ rootState, dispatch, commit }, { PK = rootState.broadcaster.focusedCompany.PK, context = false }) {
        const response = await dispatch('get_data_from_server2', {
            request: 'recherche_fichiers',
            pk_part: PK,
            tab_options: find(rootState.broadcaster.focusedCompany.contracts, (c) => {
                return !!find(c.options, ['id', rootState.broadcaster.filteredContracts[0]]);
            })?.n_contrat,
            context: context ? context : rootState.broadcaster.broadcasterSelected.PK,
        });
        if (response.success) {
            commit(
                'broadcaster/ADD_COMPANY_INFO',
                {
                    company: rootState.broadcaster.focusedCompany,
                    key: 'documents',
                    data: response.path,
                },
                { root: true },
            );
            commit(
                'broadcaster/ADD_COMPANY_INFO',
                {
                    company: rootState.broadcaster.focusedCompany,
                    key: 'documentReferences',
                    data: response.references,
                },
                { root: true },
            );
        }
    },
    async delete_request({ rootState, dispatch }, obj_id) {
        const response = await dispatch('get_data_from_server2', {
            request: 'suppr_demande_en_cours',
            demande_id: obj_id,
        });
        if (response.success) {
            await dispatch('get_demands', {
                PK: rootState.broadcaster.focusedCompany.PK,
            });
        }
    },
    async createFileFromUrl({ dispatch }, { name, type, url }) {
        const image = await dispatch('fetchBlob', url);
        const file = new File([image], name, { type });
        file.uid = uuidv4();
        return file;
    },
    async get_actualites({ commit }) {
        try {
            const response = await axios.post(
                constants.url + 'EXT/src-actualite.php',
                qs.stringify({
                    action: 'get_actualites',
                }),
            );
            const sanitizeHtml = { ...response.data.data, text: { ...response.data.data.text, text: DOMPurify.sanitize(response.data.data.text.text) } };
            commit('setCarouselContent', sanitizeHtml);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    },
    async get_actualites_backoffice({ commit }) {
        try {
            const response = await axios.post(
                constants.url + 'EXT/src-actualite.php',
                qs.stringify({
                    action: 'get_actualites_backoffice',
                }),
            );
            commit('setCarouselContentBackOffice', response.data.data);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    },
    async set_actu({ commit }, data) {
        try {
            const formData = new FormData();
            formData.append('text', DOMPurify.sanitize(data.text));
            formData.append('actus', JSON.stringify(data.actus));

            const response = await axios.post(constants.url + 'EXT/src-actualite.php', formData, {
                params: {
                    action: 'set_actualite_backoffice',
                },
            });
            return response.data;
        } catch (error) {
            console.error(error);
        }
    },

    async get_document_diffuseur({ state, commit }) {
        try {
            const response = await axios.post(
                constants.url + 'EXT/acces_bdd.php',
                qs.stringify({
                    action: 'get_document_diffuseur',
                    pk_part: state.PKUser,
                }),
            );
            return response.data;
        } catch (error) {
            console.error(error);
        }
    },

    async get_roles({ commit }) {
        try {
            const response = await axios.post(
                constants.url + 'EXT/src-actualite.php',
                qs.stringify({
                    action: 'get_roles',
                }),
            );
            return response.data;
        } catch (error) {
            console.error(error);
        }
    },
    async get_base_documentaire({ commit }) {
        try {
            const response = await axios.post(
                constants.url + 'EXT/src-document_database.php',
                qs.stringify({
                    action: 'get_base_documentaire',
                }),
            );
            return response.data;
        } catch (error) {
            console.error(error);
        }
    },
    async get_documents_backoffice({ commit }, catId) {
        try {
            const response = await axios.post(
                constants.url + 'EXT/src-document_database.php',
                qs.stringify({
                    action: 'get_documents_backoffice',
                    category_id: catId,
                }),
            );
            return response.data;
        } catch (error) {
            console.error(error);
        }
    },
    async set_documents_backoffice({ commit }, { catId, documents }) {
        try {
            const response = await axios.post(
                constants.url + 'EXT/src-document_database.php',
                qs.stringify({
                    action: 'set_documents_backoffice',
                    category_id: catId,
                    documents: JSON.stringify(documents),
                }),
            );
            return response.data;
        } catch (error) {
            console.error(error);
        }
    },
    async export_prestation({ state, rootState }) {
        try {
            let context = undefined;
            if (rootState.broadcaster.focusedCompany?.context?.PK) {
                context = rootState.broadcaster.focusedCompany.context.PK;
            }
            const response = await axios.post(
                constants.url + 'EXT/src-prestations.php',
                qs.stringify({
                    action: 'export_prestation',
                    pk_part: rootState.broadcaster.focusedCompany.PK,
                    context: context,
                }),
            );

            return response.data;
        } catch (error) {
            console.error(error);
        }
    },
};
