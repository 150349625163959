import { SIMPLE_USER, SUBSCRIBER_USER, ADMIN_SUBSCRIBER_USER, BROADCASTER_USER, ADMIN_BROADCASTER_USER, ADMIN_USER, SUPER_ADMIN_USER } from '../constants/userConstants';

export default {
    isSimpleUser(state) {
        return SIMPLE_USER.includes(state.userType);
    },
    isSubscriberUser(state) {
        return SUBSCRIBER_USER.includes(state.userType);
    },
    isAdminSubscriberUser(state) {
        return ADMIN_SUBSCRIBER_USER.includes(state.userType);
    },
    isBroadcasterUser(state) {
        return BROADCASTER_USER.includes(state.userType);
    },
    isAdminBroadcasterUser(state) {
        return ADMIN_BROADCASTER_USER.includes(state.userType);
    },
    isAdminUser(state) {
        return ADMIN_USER.includes(state.userType);
    },
    isSuperAdminUser(state) {
        return SUPER_ADMIN_USER.includes(state.userType);
    },
    isMgpUser(state) {
        return state.userType === 9021;
    },
    isCourtier(state) {
        return state.userType === 9026;
    },
};
