<template>
    <div v-if="!loadingScreen" id="app" class="ax-flex bg-grey-250">
        <router-view />
    </div>
    <div v-else class="ax-flex flex-1 ax-align ax-justify height-100">
        <vue-loaders-ball-spin-fade-loader class="spiner" color="black" scale="2" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'App',
    data () {
        return {
        };
    },
    computed: {
        ...mapState(['loadingScreen']),
    },
};
</script>

<style lang="scss">
    #app {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        width: 100%;
        height: 100%;
        background-color: white;
        text-align: center;
        font-size: 12px;
        color: black;
        .ant-input {
            &:focus {
                box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.12);
                border: #115753 solid 1px;
            }
            &:hover {
                border: #115753 solid 1px;
            }
        }
        .ant-select-open {
            .ant-select-selection {
                border: #115753 solid 1px !important;
                box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.12);
            }
        }
        .ant-select-focused {
            .ant-select-selection {
                border: #115753 solid 1px !important;
                box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.12);
            }
        }
        .ant-select-selection {
            &:hover,
            &:active,
            &:focus {
                border: #115753 solid 1px !important;
            }
        }
        .ant-btn:hover {
            border: #115753 solid 1px !important;
        }
    }

    #loader {
        width: 100%;
        height: 100%;
        .spiner {
            position: absolute;
            margin-left: 47%;
            margin-top: 20%;
        }
    }
    .footer-modal-filter {
        display: flex;
        flex-direction: row;
        .info {
            display: flex;
            flex-direction: row;
            gap: 15px;
            color: #7d7d7d;
            .icon {
                font-size: 13px;
            }
        }
        .follow {
            margin-left: auto;
            display: flex;
            flex-direction: row;
            gap: 15px;
            .cancel {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #0000000f;
                color: #505050;
                border-radius: 8px;
                width: 75px;
                height: 35px;
            }
            .ok {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #115753;
                color: white;
                border-radius: 8px;
                width: 75px;
                height: 35px;
            }
        }
    }
</style>
