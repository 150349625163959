import { without } from 'lodash';

export default {
    OPEN_CLOSE_POP_UP_ACCOUNT(state, bool) {
        state.displayPopUpAccount = bool;
    },
    OPEN_CLOSE_HELP(state, bool) {
        state.displayHelp = bool;
        if (!state.displayHelp) {
            state.contactForm = false;
        }
    },
    OPEN_CLOSE_CONTACT_FORM(state, bool) {
        state.contactForm = bool;
    },
    SET_PK_USER(state, pk) {
        state.PKUser = pk;
    },
    SET_USER_TYPE(state, type) {
        state.userType = parseInt(type);
    },
    SET_CAN_USURPATE(state, bool) {
        state.canUsurpate = bool;
    },
    REMOVE_CONTACT_ATTACHMENTS(state, file) {
        if (file === 'all') {
            state.contactAttachments = [];
        }
        state.contactAttachments = without(state.contactAttachments, file);
    },
    ADD_CONTACT_ATTACHMENTS(state, file) {
        state.contactAttachments = [...state.contactAttachments, file];
    },
    SET_USURP_KEY(state) {
        state.usurpKey += 1;
    },
    SET_CONTROL_MODE(state, bool) {
        state.controlMode = bool;
    },
    SET_LOADING_SCREEN(state, bool) {
        state.loadingScreen = bool;
    },
    setCarouselContent(state, value) {
        state.carouselContent = value;
    },
    setCarouselContentBackOffice(state, value) {
        state.carouselContentBackOffice = value;
    },
    setDiffuseurDocs(state, value) {
        state.diffuseurDocs = value;
    },
    setBaseDocsRoles(state, value) {
        state.baseDocsRoles = value;
    },
    setBasDocumentaire(state, value) {
        state.baseDocs = value;
    },
    setSubCatDocBackOffice(state, value) {
        state.subCatDocBackOffice = value;
    },
};
