import { first } from 'lodash';
import i18next from 'i18next';
import ICU from 'i18next-icu';
import HTTP from 'i18next-http-backend';

const languages = ['fr', 'en', 'de'];
const defaultLanguage = first(languages);
const icu = new ICU();

import firstPageFR from './FirstPage/fr.json';
import firstPageEN from './FirstPage/en.json';
import broadcasterPageFR from './BroadcasterPage/fr.json';
import allMembersFR from './AllMembers/fr.json';
import myWalletFR from './MyWallet/fr.json';
import seeAccessFR from './SeeAccess/fr.json';
import mainHeaderFR from './MainHeader/fr.json';
import popUpNeedHelpFR from './PopUpNeedHelp/fr.json';
import contactFormFR from './ContactForm/fr.json';
import myProfilFR from './MyProfil/fr.json';
import documentsFR from './Documents/fr.json';
import homeMemberFR from './MemberHome/fr.json';
import myDocumentsmemberFR from './MemberDocuments/fr.json';
import myAgreementsMemberFR from './MemberAgreements/fr.json';
import cotIndMemberFR from './MemberCotInd/fr.json';
import subscriberBoardFR from './SubscriberBoard/fr.json';
import subscriberSocialReasonFR from './SubscriberSocialReason/fr.json';
import subscriberAllMembersFR from './SubscriberAllMembers/fr.json';
import simpleProfilFR from './SimpleProfil/fr.json';
import dailyCompensationFR from './DailyCompensation/fr.json';
import loginScreenFR from './LoginScreen/fr.json';
import dropOffDocsFR from './DropOffDocs/fr.json';
const resources = {
    fr: {
        translation: {
            FirstPage: firstPageFR,
            BroadcasterPage: broadcasterPageFR,
            AllMembers: allMembersFR,
            MyWallet: myWalletFR,
            SeeAccess: seeAccessFR,
            MainHeader: mainHeaderFR,
            PopUpNeedHelp: popUpNeedHelpFR,
            ContactForm: contactFormFR,
            MyProfil: myProfilFR,
            Documents: documentsFR,
            HomeMember: homeMemberFR,
            MyDocumentsMember: myDocumentsmemberFR,
            MyAgreementsMember: myAgreementsMemberFR,
            CotIndMember: cotIndMemberFR,
            SubscriberBoard: subscriberBoardFR,
            SubscriberSocialReason: subscriberSocialReasonFR,
            SubscriberAllMembers: subscriberAllMembersFR,
            SimpleProfil: simpleProfilFR,
            DailyCompensation: dailyCompensationFR,
            LoginScreen: loginScreenFR,
            DropOffDocs: dropOffDocsFR,
        },
    },
    en: {
        translation: {
            FirstPage: firstPageEN,
        },
    },
};

i18next
    .use(icu)
    .use(HTTP)
    .init({
        //lng: 'en', // if you're using a language detector, do not define the lng option
        debug: true,
        languages,
        fallbackLng: defaultLanguage,
        ns: ['translation'],
        defaultNS: 'translation',
        resources,
        /*backend: {
        loadPath: '{{lng}}',
        parse: (data) => data,
        ajax: loadLocales,
    },*/
        /*backend: {
            loadPath: '{{lng}}',
            parse: (data) => data,
            ajax: loadLocales,
        }*/
    });

export default i18next;
