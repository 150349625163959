import { v4 as uuidv4 } from 'uuid';
import _, { without, filter } from 'lodash';
import Vue from 'vue';
export default {
    SET_LEFT_SIDER_SELECTED_BUTTON(state, value) {
        state.selectedKeys = value;
    },
    SET_SELECTED_SUBSCRIBER(state, value) {
        state.SelectedSubscriberIndex = value;
    },
    DECONNEXION(state) {
        state.selectedKeys = 0;
        state.SelectedSubscriberIndex = 0;
        state.userProfil = null;
        state.rights_level = 0;
        state.displayedEtabs = [];
        state.allSubscribers = [];
        state.favoris = [];
        state.allBroadcaster = [];
        state.arrets = [];
        state.listContacts = [];
        state.listeDemandes = [];
        state.listeRibs = [];
        state.listeContrats = [];
        state.listeDocuments = [];
        state.filtersDoc = [];
        state.filtersAdh = [];
        state.filtersDues = [];
        state.listeAdh = [];
        state.listeAccess = [];
        state.broadcasterPK = 0;
        state.tabOnglet = 0;
        state.broadcasterSelected = {};
    },
    SET_USER_PROFIL(state, obj) {
        state.userProfil = obj;
    },
    UPDATE_ARRETS(state, array) {
        state.arrets = array;
    },
    SET_LIST_CONTACTS(state, array) {
        state.listContacts = array;
    },
    SET_LIST_DEMANDES(state, array) {
        state.listeDemandes = array;
    },
    SET_LIST_RIBS(state, array) {
        state.listeRibs = array;
    },
    SET_LIST_CONTRATS(state, array) {
        state.listeContrats = array;
    },
    SET_LIST_DOCUMENTS(state, array) {
        state.listeDocuments = array;
    },
    SET_LIST_ADH(state, array) {
        state.listeAdh = array;
    },
    SET_LIST_ACCESS(state, array) {
        state.listeAccess = [...array];
    },
    CHANGE_LIST_ACCESS(state, obj) {
        state.listeAccess[obj.index] = obj.value;
    },
    SET_DISPLAYED_ETABS(state, array) {
        state.displayedEtabs = array;
    },
    SET_SELECTED_PK_USER(state, pk) {
        state.selectedPkUser = pk;
    },
    SET_DOC_FILTERS(state, array) {
        state.filtersDoc = array;
    },
    SET_ADH_FILTERS(state, array) {
        state.filtersAdh = array;
    },
    SET_DUES_FILTERS(state, array) {
        state.filtersDues = array;
    },
    SET_DOCUMENTS_PART(state, array) {
        state.documentsPart = array;
    },
    SET_ALL_SUBSCRIBERS(state, array) {
        state.allSubscribers = array;
    },
    SET_FAVORITE(state, array) {
        state.favoris = array;
    },
    SET_RIGHTS_LEVEL(state, level) {
        state.rights_level = level;
    },
    SET_ALL_BROADCASTER(state, array) {
        const list = [];
        if (array) {
            array.forEach((element) => {
                list.push({ ...element, ['id']: uuidv4() });
            });
        }
        state.allBroadcaster = list;
    },
    SET_BROADCASTER_PK(state, value) {
        state.broadcasterPK = value;
    },
    SET_TAB_ONGLET(state, value) {
        state.tabOnglet = value;
    },
    SET_RAD_CONTRACT(state, value) {
        state.isRad = value;
    },
    SET_CONTRACTS_INDIV(state, array) {
        const list = [];
        if (array) {
            array.forEach((element) => {
                list.push({ ...element, ['id']: uuidv4() });
            });
        }
        state.contractsIndiv = list;
    },
    SET_FILTERED_DOCUMENTS(state, array) {
        state.myDocsFilter = [...array];
    },
    SET_DOCUMENT_REFERENCES(state, obj) {
        state.documentReferences = obj;
    },
    SET_MY_COTISATIONS(state, array) {
        state.cotisations = array;
    },
    DELETE_COTISATIONS(state) {
        state.cotisations = [];
    },
    SET_MY_INDEMNISATION(state, obj) {
        state.indemnisations = obj;
    },
    DELETE_INDEMNISATION(state) {
        state.indemnisations = [];
    },
    SET_SELECTED_CONTRACTS(state, id) {
        state.selectedContracts = state.selectedContracts.indexOf(id) >= 0 ? without(state.selectedContracts, id) : [...state.selectedContracts, id];
    },
    DELETE_SELECTED_CONTRACTS(state) {
        state.selectedContracts = [];
    },
    SET_SELECTED_MYDOCUMENT_YEAR(state, value) {
        state.myDocumentYear = value;
    },
    SET_COMPANIES_MODAL(state, value) {
        state.companiesModalVisisble = value;
    },
    SET_COMPANIES(state, obj) {
        state.companies = [];
        if (obj) {
            obj.forEach((element) => {
                const id = uuidv4();
                state.companies.push({ ...element, id });
            });
        }
    },
    ADD_COMPANY(state, el) {
        if (state.companiesSelected.length === 0) {
            state.companiesSelected = [el];
        } else {
            state.companiesSelected = [...state.companiesSelected, el];
        }
    },
    ADD_COMPANY_FETCHING(state, bool) {
        state.fetchingCompany = bool;
    },
    ADD_BROADCASTER(state, el) {
        state.broadcasterSelected = el;
    },
    DELETE_COMPANY(state, el) {
        state.companiesSelected = _.without(state.companiesSelected, el);
    },
    SET_FOCUSED_COMPANY(state, el) {
        state.focusedCompany = el;
        state.filteredContracts = [];
    },
    SET_PENDING_COT(state, bool) {
        state.pendingCot = bool;
    },
    SET_PENDING_ADH(state, bool) {
        state.pendingAdh = bool;
    },
    SET_DUE_FILTER(state, value) {
        if (typeof value !== 'string') {
            state.cotisationsFIlterYear = value;
        } else {
            if (value === 'reset') {
                if (
                    !filter(state.focusedCompany.cotisations, (cot) => {
                        return parseInt(cot.annee) === state.cotisationsFIlterYear;
                    })
                ) {
                    state.cotisationsFIlterYear = 'Tous';
                }
            } else {
                state.cotisationsFIlterYear = value;
            }
        }
    },
    SET_FILTERED_CONTRACT(state, array) {
        state.filteredContracts = array;
    },
    CLEAR_WALLET(state) {
        state.filteredContracts = [];
        state.focusedCompany = {
            adresse: '',
            adresse_comp1: '',
            adresse_comp2: '',
            email: '',
            tel: '',
            PK: -1,
            lib: '',
            dossier: '',
            libelle: '',
            stoppedPeople: [],
            contacts: [],
            demands: [],
            rib_iban: [],
            contracts: [],
            documents: [],
            members: [],
            cotisations: [],
        };
        state.companiesSelected = [];
        state.selectedContracts = [];
        state.favoris = [];
        state.allConstPartenaires = [];
        state.userLinkPart = [];
        state.allPartenaires = [];
        state.usersToDisplay = [];
        state.data = [];
        state.companies = [];
        state.allPartenaires = [];
    },
    ADD_COMPANY_INFO(state, obj) {
        Vue.set(state.focusedCompany, obj.key, obj.data);
    },
    DELETE_FILTERED_CONTRACT(state) {
        if (state.focusedCompany.filteredContract) {
            state.focusedCompany.filteredContract = [];
        }
    },
    SET_FILTER_STATE(state, value) {
        state.finishLoadContract = value;
    },

    SET_FILTER(state, value) {
        state.filterData = value;
    },

    SET_ACTIF(state, value) {
        state.actifData = value;
    },

    SET_SEARCH_OBJECT(state, value) {
        state.searchObjectData = value;
    },

    SET_SEARCH_TEXT(state, value) {
        state.searchTextData = value;
    },

    SET_DATA(state, value) {
        state.data = [...value];
    },
    SET_USERS_TO_DISPLAY(state, value) {
        state.usersToDisplay = [...value];
    },
    SET_CURRENT(state, value) {
        state.current = value;
    },
    SET_LINK_MODAL_VISIBLE(state, value) {
        state.linkModalVisible = value;
    },
    SET_LINK_MODAL_INFO(state, info) {
        state.linkModalInfo = info;
    },
    SET_LINK_SELECTED_PART(state, pk) {
        state.selectedPart = pk;
    },
    SET_SELECTED_PART_TO_FETCH_PK(state, pk) {
        state.selectedPartToFetchPK = pk;
    },
    SET_SEARCH_ALL_PARTENAIRES(state, value) {
        state.searchAllPartenaires = value;
    },
    SET_ALL_PARTENAIRES(state, array) {
        state.allPartenaires = [...array];
    },
    SET_ALL_PARTENAIRES_LOADING(state, bool) {
        state.allPartenairesLoading = bool;
    },
    SET_ALL_CONST_PARTENAIRES(state, array) {
        state.allConstPartenaires = [...array];
    },
    SET_USER_LINK_PART(state, part) {
        state.userLinkPart = [...part];
    },
    setDepositModaleVisible(state, value) {
        state.depositModaleVisible = value;
    },
};
