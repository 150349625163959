export default {
    setIsCollectif(state, value) {
        state.isCollectif = value;
    },
    setNavigation(state, selectedNav) {
        state.selectedNav = selectedNav;
    },
    setInputValue(state, obj) {
        state.inputs = { ...state.inputs, ...obj };
    },
    setInputFiles(state, files) {
        state.inputs.files = files;
    },
    addInputFiles(state, file) {
        state.inputs.files.push(file);
    },
    clearInputs(state) {
        state.inputs = [];
    },
    removeInputFile(state, uid) {
        if (uid.includes('all')) {
            state.inputs['files'] = [];
        }
        state.inputs['files'] = state.inputs['files'].filter((el) => el.uid !== uid);
    },
    setLoading(state, bool) {
        state.loading = bool;
    },
    setSaveLoading(state, value) {
        state.saveLoading = value;
    },
};
