import { filter, find, sortBy } from 'lodash';

export default {
    async add_company({ dispatch, commit, state }, el) {
        if (state.focusedCompany.PK !== el.PK) {
            if (el?.original_PK) {
                el = { ...el, PK: el.original_PK };
            }
            commit('ADD_COMPANY', el);
            commit('ADD_COMPANY_FETCHING', true);
            commit('SET_FOCUSED_COMPANY', el);
            await dispatch('get_arrets', { PK: el.PK, context: el.original_PK ? el.context.PK : false }, { root: true });
            await dispatch('get_contact', { PK: el.PK, context: el.original_PK ? el.context.PK : false }, { root: true });
            await dispatch('get_demands', { PK: el.PK, context: el.original_PK ? el.context.PK : false }, { root: true });
            await dispatch('get_rib_iban', { PK: el.PK, context: el.original_PK ? el.context.PK : false }, { root: true });
            await dispatch('get_contracts', { PK: el.PK, context: el.original_PK ? el.context.PK : false }, { root: true });
            await dispatch('get_documents', { PK: el.PK, context: el.original_PK ? el.context.PK : false }, { root: true });
            await dispatch('get_members', { PK: el.PK, allMemebers: true, context: el.original_PK ? el.context.PK : false }, { root: true });
            await dispatch('get_cotisation', { PK: el.PK, allCot: true, context: el.original_PK ? el.context.PK : false }, { root: true });
            commit('ADD_COMPANY_FETCHING', false);
        }
    },
    async add_broadcaster({ commit, dispatch }, el) {
        commit('ADD_BROADCASTER', el);

        const res = await dispatch(
            'get_data_from_server2',
            {
                request: 'get_subscribers',
                pk_part: el.PK,
            },
            { root: true },
        );
        if (res.adherent_collectif) {
            commit('broadcaster/SET_COMPANIES', res.adherent_collectif, {
                root: true,
            });
        } else {
            commit('broadcaster/SET_COMPANIES', [], { root: true });
        }
    },
    delete_company({ state, commit }, el) {
        commit('DELETE_COMPANY', el);
        if (state.focusedCompany.id === el.id) {
            if (state.companiesSelected.length > 0) {
                commit('SET_FOCUSED_COMPANY', state.companiesSelected[0]);
            } else {
                commit('SET_FOCUSED_COMPANY', {
                    adresse: '',
                    adresse_comp1: '',
                    adresse_comp2: '',
                    email: '',
                    tel: '',
                    PK: -1,
                    lib: '',
                    dossier: '',
                    libelle: '',
                    stoppedPeople: [],
                    contacts: [],
                    demands: [],
                    rib_iban: [],
                    contracts: [],
                    documents: [],
                    members: [],
                    cotisations: [],
                });
            }
        }
    },

    async fetch_account({ commit, dispatch, state }) {
        const response = await dispatch(
            'get_data_from_server2',
            {
                request: 'recherche_comptes',
                pk_part: state.selectedPartToFetchPK,
            },
            { root: true },
        );
        if (response.success) {
            commit(
                'broadcaster/SET_LIST_ACCESS',
                filter(response.comptes, (compte) => {
                    if (compte.nom.length > 1) return compte;
                }),
                { root: true },
            );
        }
    },

    async getLinkPartModal({ commit, dispatch, getters, state }, user) {
        const response = await dispatch(
            'get_data_from_server2',
            {
                request: 'get_link_part',
                user_id: user.cx_id,
            },
            { root: true },
        );
        let personne = {
            name: user.nom,
            firstName: user.prenom,
            id: user.cx_id,
            rightLevel: response.right,
            rightLevelLib: user.droits,
            linked_part: []
        };
        commit('SET_LINK_MODAL_INFO', personne);

        const availablePart = getters.availablePartenaires;
        const linkedPart = [];
        response.linked_part.forEach((pk) => {
            const find_part = find(availablePart, (part) => {
                return part.PK === pk;
            });
            if (find_part?.lib) {
                linkedPart.push({ PK: pk, lib: find_part?.lib });
            }
        });
        sortBy(linkedPart, (part) => {
            return part.lib;
        });

        personne = { ...personne, linked_part: linkedPart };
        commit('SET_LINK_MODAL_INFO', personne);
        commit('SET_LINK_MODAL_VISIBLE', true);
    },

    async getLinkPart({ dispatch, commit }, cx_id) {
        const response = await dispatch(
            'get_data_from_server2',
            {
                request: 'get_link_part',
                user_id: cx_id,
            },
            { root: true },
        );
        if (response.success) {
            commit('SET_USER_LINK_PART', response.linked_part);
        }
    },

    async setLink({ dispatch, state }) {
        let pk_list = [];
        state.linkModalInfo.linked_part.forEach((part) => {
            pk_list.push(part.PK);
        });
        await dispatch(
            'get_data_from_server2',
            {
                request: 'set_link_part',
                user_id: state.linkModalInfo.id,
                list_part: JSON.stringify(pk_list),
            },
            { root: true },
        );
    },
    async fetchPart({ dispatch, commit }, search) {
        const response = await dispatch(
            'get_data_from_server2',
            {
                request: 'all_partenaires',
                search: search,
            },
            { root: true },
        );
        if (response.success) {
            commit('SET_ALL_CONST_PARTENAIRES', response.partenaires);
        }
    },
    async searchPartenaire({ dispatch, commit, state }, search) {
        commit('SET_ALL_PARTENAIRES_LOADING', true);
        const response = await dispatch(
            'get_data_from_server2',
            {
                request: 'all_partenaires',
                search: search,
            },
            { root: true },
        );
        commit('SET_ALL_PARTENAIRES_LOADING', false);
        if (response.success && response.search === state.searchAllPartenaires) {
            commit('SET_ALL_PARTENAIRES', response.partenaires);
        }
    },
};
